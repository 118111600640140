import $ from 'jquery';
import autosize from 'autosize';
import 'select2';
import 'select2/dist/css/select2.min.css';
import Rails from '@rails/ujs';
import '@popperjs/core';
import { Alert } from 'bootstrap';
import '@oddcamp/cocoon-vanilla-js';
import _ from 'lodash/fp';
import '@hotwired/turbo-rails';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import * as CookieConsent from 'vanilla-cookieconsent';

import './css/site.scss';
import onboarding from './lib/onboarding';
import references from './lib/references';

if (!window._rails_loaded) Rails.start();

const fadeOutFlash = () => {
  const alertNode = document.querySelector('.alert-dismissible');
  if (_.isNil(alertNode)) return;

  const alert = new Alert(alertNode);
  alert.close();
};

document.addEventListener('turbo:load', () => {
  autosize(document.querySelector('textarea'));
  onboarding();
  references();

  if ($('.select2').length === 0) {
    $('#reference_list_names').select2({ tags: true });
    $('#list_names').select2({ tags: true });
  }

  window.setTimeout(fadeOutFlash, 2000);

  CookieConsent.run({
    categories: {
      necessary: {
        enabled: true,
        readOnly: true
      },
      analytics: {
        enabled: true,
        readOnly: false,
        autoClear: {
          reloadPage: true
        }
      },
    },
    guiOptions: {
      consentModal: {
        layout: 'box',
        position: 'bottom left',
      }
    },
    language: {
      default: 'en',
      translations: {
        en: {
          consentModal: {
            title: 'We use cookies',
            description: 'We use cookies to improve your experience and analyze traffic. Manage your preferences or accept all.',
            acceptAllBtn: 'Accept all',
            acceptNecessaryBtn: 'Reject all',
            showPreferencesBtn: 'Manage Individual preferences'
          },
          preferencesModal: {
            title: 'Manage cookie preferences',
            acceptAllBtn: 'Accept all',
            acceptNecessaryBtn: 'Reject all',
            savePreferencesBtn: 'Accept current selection',
            closeIconLabel: 'Close modal',
            sections: [
              {
                title: 'Somebody said ... cookies?',
                description: 'By using cookies, we ensure that our website functions properly and provide insights into how you use it to improve our services.'
              },
              {
                title: 'Strictly Necessary cookies',
                description: 'These cookies are essential for the proper functioning of the website and cannot be disabled.',
                linkedCategory: 'necessary'
              },
              {
                title: 'Performance and Analytics',
                description: 'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
                linkedCategory: 'analytics'
              },
              {
                title: 'More information',
                description: 'For any queries in relation to my policy on cookies and your choices, please contact us'
              }
            ]
          }
        }
      }
    }
  });
});
